import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const MousePointer2: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'm4 4 7.07 17 2.51-7.39L21 11.07z' }]],
];

export default MousePointer2;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Laptop2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '3', y: '4', width: '18', height: '12', rx: '2', ry: '2' }],
    ['line', { x1: '2', y1: '20', x2: '22', y2: '20' }],
  ],
];

export default Laptop2;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronUp: IconNode = [
  'svg',
  defaultAttributes,
  [['polyline', { points: '18 15 12 9 6 15' }]],
];

export default ChevronUp;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowBigLeft: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'm3 12 7-7v4h11v6H10v4z' }]],
];

export default ArrowBigLeft;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const FolderCog2: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M4 20h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2Z',
      },
    ],
    ['circle', { cx: '12', cy: '13', r: '2' }],
    ['path', { d: 'M12 10v1' }],
    ['path', { d: 'M12 15v1' }],
    ['path', { d: 'm14.6 11.5-.87.5' }],
    ['path', { d: 'm10.27 14-.87.5' }],
    ['path', { d: 'm14.6 14.5-.87-.5' }],
    ['path', { d: 'm10.27 12-.87-.5' }],
  ],
];

export default FolderCog2;

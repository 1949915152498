import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const AlarmClock: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '13', r: '8' }],
    ['path', { d: 'M12 9v4l2 2' }],
    ['path', { d: 'M5 3 2 6' }],
    ['path', { d: 'm22 6-3-3' }],
    ['path', { d: 'm6 19-2 2' }],
    ['path', { d: 'm18 19 2 2' }],
  ],
];

export default AlarmClock;

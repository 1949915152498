import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const FileAudio: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M17.5 22h.5c.5 0 1-.2 1.4-.6.4-.4.6-.9.6-1.4V7.5L14.5 2H6c-.5 0-1 .2-1.4.6C4.2 3 4 3.5 4 4v3',
      },
    ],
    ['polyline', { points: '14 2 14 8 20 8' }],
    ['path', { d: 'M10 20v-1a2 2 0 1 1 4 0v1a2 2 0 1 1-4 0Z' }],
    ['path', { d: 'M6 20v-1a2 2 0 1 0-4 0v1a2 2 0 1 0 4 0Z' }],
    ['path', { d: 'M2 19v-3a6 6 0 0 1 12 0v3' }],
  ],
];

export default FileAudio;

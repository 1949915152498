import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Navigation2Off: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M9.31 9.31 5 21l7-4 7 4-1.17-3.17' }],
    ['path', { d: 'M14.53 8.88 12 2l-1.17 3.17' }],
    ['line', { x1: '2', y1: '2', x2: '22', y2: '22' }],
  ],
];

export default Navigation2Off;

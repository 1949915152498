import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronRight: IconNode = [
  'svg',
  defaultAttributes,
  [['polyline', { points: '9 18 15 12 9 6' }]],
];

export default ChevronRight;

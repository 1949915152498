import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const AlignStartVertical: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '6', y: '14', width: '9', height: '6', rx: '2' }],
    ['rect', { x: '6', y: '4', width: '16', height: '6', rx: '2' }],
    ['path', { d: 'M2 2v20' }],
  ],
];

export default AlignStartVertical;

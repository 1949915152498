import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Cake: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 21v-8a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v8' }],
    ['path', { d: 'M4 16s.5-1 2-1 2.5 2 4 2 2.5-2 4-2 2.5 2 4 2 2-1 2-1' }],
    ['path', { d: 'M2 21h20' }],
    ['path', { d: 'M7 8v2' }],
    ['path', { d: 'M12 8v2' }],
    ['path', { d: 'M17 8v2' }],
    ['path', { d: 'M7 4h.01' }],
    ['path', { d: 'M12 4h.01' }],
    ['path', { d: 'M17 4h.01' }],
  ],
];

export default Cake;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowBigUp: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M9 21V10H5l7-7 7 7h-4v11z' }]],
];

export default ArrowBigUp;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Umbrella: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M22 12a9.92 9.92 0 0 0-3.24-6.41 10.12 10.12 0 0 0-13.52 0A9.92 9.92 0 0 0 2 12Z',
      },
    ],
    ['path', { d: 'M12 12v8a2 2 0 0 0 4 0' }],
    ['line', { x1: '12', y1: '2', x2: '12', y2: '3' }],
  ],
];

export default Umbrella;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowBigRight: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'm21 12-7-7v4H3v6h11v4z' }]],
];

export default ArrowBigRight;

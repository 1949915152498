import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const FileJson: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      },
    ],
    ['polyline', { points: '14 2 14 8 20 8' }],
    [
      'path',
      {
        d: 'M10 12a1 1 0 0 0-1 1v1a1 1 0 0 1-1 1 1 1 0 0 1 1 1v1a1 1 0 0 0 1 1',
      },
    ],
    [
      'path',
      {
        d: 'M14 18a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1 1 1 0 0 1-1-1v-1a1 1 0 0 0-1-1',
      },
    ],
  ],
];

export default FileJson;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Mouse: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '6', y: '3', width: '12', height: '18', rx: '6' }],
    ['path', { d: 'M12 7v4' }],
  ],
];

export default Mouse;

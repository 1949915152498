import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Minimize: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M8 3v3a2 2 0 0 1-2 2H3' }],
    ['path', { d: 'M21 8h-3a2 2 0 0 1-2-2V3' }],
    ['path', { d: 'M3 16h3a2 2 0 0 1 2 2v3' }],
    ['path', { d: 'M16 21v-3a2 2 0 0 1 2-2h3' }],
  ],
];

export default Minimize;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowUpDown: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '11 17 7 21 3 17' }],
    ['line', { x1: '7', y1: '21', x2: '7', y2: '9' }],
    ['polyline', { points: '21 7 17 3 13 7' }],
    ['line', { x1: '17', y1: '15', x2: '17', y2: '3' }],
  ],
];

export default ArrowUpDown;

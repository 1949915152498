import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const LayoutList: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '3', y: '14', width: '7', height: '7' }],
    ['rect', { x: '3', y: '3', width: '7', height: '7' }],
    ['line', { x1: '14', y1: '4', x2: '21', y2: '4' }],
    ['line', { x1: '14', y1: '9', x2: '21', y2: '9' }],
    ['line', { x1: '14', y1: '15', x2: '21', y2: '15' }],
    ['line', { x1: '14', y1: '20', x2: '21', y2: '20' }],
  ],
];

export default LayoutList;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Loader2: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M21 12a9 9 0 1 1-6.219-8.56' }]],
];

export default Loader2;

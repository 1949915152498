import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Inbox: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '22 12 16 12 14 15 10 15 8 12 2 12' }],
    [
      'path',
      {
        d: 'M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z',
      },
    ],
  ],
];

export default Inbox;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Scroll: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M10 17v2a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2V5a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v3h3',
      },
    ],
    ['path', { d: 'M22 17v2a2 2 0 0 1-2 2H8' }],
    ['path', { d: 'M19 17V5a2 2 0 0 0-2-2H4' }],
    ['path', { d: 'M22 17H10' }],
  ],
];

export default Scroll;

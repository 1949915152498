import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Medal: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M7.21 15 2.66 7.14a2 2 0 0 1 .13-2.2L4.4 2.8A2 2 0 0 1 6 2h12a2 2 0 0 1 1.6.8l1.6 2.14a2 2 0 0 1 .14 2.2L16.79 15',
      },
    ],
    ['path', { d: 'M11 12 5.12 2.2' }],
    ['path', { d: 'm13 12 5.88-9.8' }],
    ['path', { d: 'M8 7h8' }],
    ['circle', { cx: '12', cy: '17', r: '5' }],
    ['path', { d: 'M12 18v-2h-.5' }],
  ],
];

export default Medal;

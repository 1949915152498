import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronsUp: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '17 11 12 6 7 11' }],
    ['polyline', { points: '17 18 12 13 7 18' }],
  ],
];

export default ChevronsUp;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const UserCheck: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2' }],
    ['circle', { cx: '9', cy: '7', r: '4' }],
    ['polyline', { points: '16 11 18 13 22 9' }],
  ],
];

export default UserCheck;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Navigation2: IconNode = [
  'svg',
  defaultAttributes,
  [['polygon', { points: '12 2 19 21 12 17 5 21 12 2' }]],
];

export default Navigation2;

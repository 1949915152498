import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const MonitorSmartphone: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M18 8V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8' }],
    ['path', { d: 'M10 19v-3.96 3.15' }],
    ['path', { d: 'M7 19h5' }],
    ['rect', { x: '16', y: '12', width: '6', height: '10', rx: '2' }],
  ],
];

export default MonitorSmartphone;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const SignalZero: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M2 20h.01' }]],
];

export default SignalZero;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Subscript: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm4 5 8 8' }],
    ['path', { d: 'm12 5-8 8' }],
    [
      'path',
      {
        d: 'M20 19h-4c0-1.5.44-2 1.5-2.5S20 15.33 20 14c0-.47-.17-.93-.48-1.29a2.11 2.11 0 0 0-2.62-.44c-.42.24-.74.62-.9 1.07',
      },
    ],
  ],
];

export default Subscript;

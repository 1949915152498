import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const FilePlus2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4' }],
    ['polyline', { points: '14 2 14 8 20 8' }],
    ['path', { d: 'M3 15h6' }],
    ['path', { d: 'M6 12v6' }],
  ],
];

export default FilePlus2;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const DatabaseBackup: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['ellipse', { cx: '12', cy: '5', rx: '9', ry: '3' }],
    ['path', { d: 'M3 12c0 1.18 2.03 2.2 5 2.7' }],
    ['path', { d: 'M21 5v4.5' }],
    ['path', { d: 'm12 16 1.27-1.35a4.75 4.75 0 1 1 .41 5.74' }],
    ['path', { d: 'M12 12v4h4' }],
    ['path', { d: 'M3 5v14c0 1.43 2.97 2.63 7 2.93' }],
  ],
];

export default DatabaseBackup;

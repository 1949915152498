import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Tag: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M12 2H2v10l9.29 9.29c.94.94 2.48.94 3.42 0l6.58-6.58c.94-.94.94-2.48 0-3.42L12 2Z',
      },
    ],
    ['path', { d: 'M7 7h.01' }],
  ],
];

export default Tag;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const FishOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M18 12.47v.03M18 12v.47M17.525 17.526A6.744 6.744 0 0 1 15 18c-3.56 0-7.56-2.53-8.5-6 .348-1.28 1.114-2.433 2.121-3.38M12.065 6.532A8.802 8.802 0 0 1 15 6c3.56 0 6.06 2.54 7 6-.309 1.14-.786 2.177-1.413 3.058',
      },
    ],
    [
      'path',
      {
        d: 'M7 10.67C7 8 5.58 5.97 2.73 5.5c-1 1.5-1 5 .23 6.5-1.24 1.5-1.24 5-.23 6.5C5.58 18.03 7 16 7 13.33M14.48 8.958A9.77 9.77 0 0 1 16 6.07M16 17.93a9.77 9.77 0 0 1-1.728-3.618',
      },
    ],
    [
      'path',
      {
        d: 'm16.01 17.93-.23 1.4A2 2 0 0 1 13.8 21H9.5a5.96 5.96 0 0 0 1.49-3.98M8.53 3h5.27a2 2 0 0 1 1.98 1.67l.23 1.4M2 2l20 20',
      },
    ],
  ],
];

export default FishOff;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Cross: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M11 2a2 2 0 0 0-2 2v5H4a2 2 0 0 0-2 2v2c0 1.1.9 2 2 2h5v5c0 1.1.9 2 2 2h2a2 2 0 0 0 2-2v-5h5a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2h-5V4a2 2 0 0 0-2-2h-2z',
      },
    ],
  ],
];

export default Cross;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Volume: IconNode = [
  'svg',
  defaultAttributes,
  [['polygon', { points: '11 5 6 9 2 9 2 15 6 15 11 19 11 5' }]],
];

export default Volume;

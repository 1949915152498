import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Filter: IconNode = [
  'svg',
  defaultAttributes,
  [['polygon', { points: '22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3' }]],
];

export default Filter;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Sigma: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M18 7V4H6l6 8-6 8h12v-3' }]],
];

export default Sigma;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const GitBranchPlus: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M6 3v12' }],
    ['path', { d: 'M18 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' }],
    ['path', { d: 'M6 21a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' }],
    ['path', { d: 'M15 6a9 9 0 0 0-9 9' }],
    ['path', { d: 'M18 15v6' }],
    ['path', { d: 'M21 18h-6' }],
  ],
];

export default GitBranchPlus;

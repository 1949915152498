import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const CloudCog: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 16.2A4.5 4.5 0 0 0 17.5 8h-1.8A7 7 0 1 0 4 14.9' }],
    ['circle', { cx: '12', cy: '17', r: '3' }],
    ['path', { d: 'M12 13v1' }],
    ['path', { d: 'M12 20v1' }],
    ['path', { d: 'M16 17h-1' }],
    ['path', { d: 'M9 17H8' }],
    ['path', { d: 'm15 14-.88.88' }],
    ['path', { d: 'M9.88 19.12 9 20' }],
    ['path', { d: 'm15 20-.88-.88' }],
    ['path', { d: 'M9.88 14.88 9 14' }],
  ],
];

export default CloudCog;

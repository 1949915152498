import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const RotateCcw: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 2v6h6' }],
    ['path', { d: 'M3 13a9 9 0 1 0 3-7.7L3 8' }],
  ],
];

export default RotateCcw;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const BatteryCharging: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M15 7h1a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-2' }],
    ['path', { d: 'M6 7H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h1' }],
    ['path', { d: 'm11 7-3 5h4l-3 5' }],
    ['line', { x1: '22', x2: '22', y1: '11', y2: '13' }],
  ],
];

export default BatteryCharging;

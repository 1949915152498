import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Network: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '9', y: '2', width: '6', height: '6' }],
    ['rect', { x: '16', y: '16', width: '6', height: '6' }],
    ['rect', { x: '2', y: '16', width: '6', height: '6' }],
    ['path', { d: 'M5 16v-4h14v4' }],
    ['path', { d: 'M12 12V8' }],
  ],
];

export default Network;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Milestone: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M18 6H5a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h13l4-3.5L18 6Z' }],
    ['path', { d: 'M12 13v9' }],
    ['path', { d: 'M12 2v4' }],
  ],
];

export default Milestone;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Map: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polygon', { points: '3 6 9 3 15 6 21 3 21 18 15 21 9 18 3 21' }],
    ['line', { x1: '9', y1: '3', x2: '9', y2: '18' }],
    ['line', { x1: '15', y1: '6', x2: '15', y2: '21' }],
  ],
];

export default Map;

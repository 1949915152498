import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const FileVolume2: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      },
    ],
    ['polyline', { points: '14 2 14 8 20 8' }],
    ['path', { d: 'M11.5 13.5c.32.4.5.94.5 1.5s-.18 1.1-.5 1.5' }],
    ['path', { d: 'M15 12c.64.8 1 1.87 1 3s-.36 2.2-1 3' }],
    ['path', { d: 'M8 15h.01' }],
  ],
];

export default FileVolume2;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const SortDesc: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M11 5h10' }],
    ['path', { d: 'M11 9h7' }],
    ['path', { d: 'M11 13h4' }],
    ['path', { d: 'm3 17 3 3 3-3' }],
    ['path', { d: 'M6 18V4' }],
  ],
];

export default SortDesc;

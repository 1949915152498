import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Crown: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'm2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14' }]],
];

export default Crown;

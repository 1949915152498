import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronDown: IconNode = [
  'svg',
  defaultAttributes,
  [['polyline', { points: '6 9 12 15 18 9' }]],
];

export default ChevronDown;

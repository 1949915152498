import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const TreeDeciduous: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M8 19h8a4 4 0 0 0 3.8-2.8 4 4 0 0 0-1.6-4.5c1-1.1 1-2.7.4-4-.7-1.2-2.2-2-3.6-1.7a3 3 0 0 0-3-3 3 3 0 0 0-3 3c-1.4-.2-2.9.5-3.6 1.7-.7 1.3-.5 2.9.4 4a4 4 0 0 0-1.6 4.5A4 4 0 0 0 8 19Z',
      },
    ],
    ['path', { d: 'M12 19v3' }],
  ],
];

export default TreeDeciduous;

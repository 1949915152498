import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Bold: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z' }],
    ['path', { d: 'M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z' }],
  ],
];

export default Bold;

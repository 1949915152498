import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const SlidersHorizontal: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '21', y1: '4', x2: '14', y2: '4' }],
    ['line', { x1: '10', y1: '4', x2: '3', y2: '4' }],
    ['line', { x1: '21', y1: '12', x2: '12', y2: '12' }],
    ['line', { x1: '8', y1: '12', x2: '3', y2: '12' }],
    ['line', { x1: '21', y1: '20', x2: '16', y2: '20' }],
    ['line', { x1: '12', y1: '20', x2: '3', y2: '20' }],
    ['line', { x1: '14', y1: '2', x2: '14', y2: '6' }],
    ['line', { x1: '8', y1: '10', x2: '8', y2: '14' }],
    ['line', { x1: '16', y1: '18', x2: '16', y2: '22' }],
  ],
];

export default SlidersHorizontal;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Heading3: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 12h8' }],
    ['path', { d: 'M4 18V6' }],
    ['path', { d: 'M12 18V6' }],
    ['path', { d: 'M17.5 10.5c1.7-1 3.5 0 3.5 1.5a2 2 0 0 1-2 2' }],
    ['path', { d: 'M17 17.5c2 1.5 4 .3 4-1.5a2 2 0 0 0-2-2' }],
  ],
];

export default Heading3;

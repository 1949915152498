import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ZapOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '12.41 6.75 13 2 10.57 4.92' }],
    ['polyline', { points: '18.57 12.91 21 10 15.66 10' }],
    ['polyline', { points: '8 8 3 14 12 14 11 22 16 16' }],
    ['line', { x1: '2', y1: '2', x2: '22', y2: '22' }],
  ],
];

export default ZapOff;

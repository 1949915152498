import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Quote: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M3 21c3 0 7-1 7-8V5c0-1.25-.756-2.017-2-2H4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2 1 0 1 0 1 1v1c0 1-1 2-2 2s-1 .008-1 1.031V20c0 1 0 1 1 1z',
      },
    ],
    [
      'path',
      {
        d: 'M15 21c3 0 7-1 7-8V5c0-1.25-.757-2.017-2-2h-4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2h.75c0 2.25.25 4-2.75 4v3c0 1 0 1 1 1z',
      },
    ],
  ],
];

export default Quote;

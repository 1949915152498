import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const FileDown: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      },
    ],
    ['polyline', { points: '14 2 14 8 20 8' }],
    ['path', { d: 'M12 18v-6' }],
    ['path', { d: 'm9 15 3 3 3-3' }],
  ],
];

export default FileDown;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronsDown: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '7 13 12 18 17 13' }],
    ['polyline', { points: '7 6 12 11 17 6' }],
  ],
];

export default ChevronsDown;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const FlagTriangleLeft: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M17 22V2L7 7l10 5' }]],
];

export default FlagTriangleLeft;

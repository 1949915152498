import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const SortAsc: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M11 11h4' }],
    ['path', { d: 'M11 15h7' }],
    ['path', { d: 'M11 19h10' }],
    ['path', { d: 'M9 7 6 4 3 7' }],
    ['path', { d: 'M6 6v14' }],
  ],
];

export default SortAsc;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const CheckCircle2: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z',
      },
    ],
    ['path', { d: 'm9 12 2 2 4-4' }],
  ],
];

export default CheckCircle2;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const CalendarX2: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M21 13V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8' },
    ],
    ['line', { x1: '16', y1: '2', x2: '16', y2: '6' }],
    ['line', { x1: '8', y1: '2', x2: '8', y2: '6' }],
    ['line', { x1: '3', y1: '10', x2: '21', y2: '10' }],
    ['line', { x1: '17', y1: '17', x2: '22', y2: '22' }],
    ['line', { x1: '17', y1: '22', x2: '22', y2: '17' }],
  ],
];

export default CalendarX2;

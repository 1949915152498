import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Dna: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 15c6.667-6 13.333 0 20-6' }],
    ['path', { d: 'M9 22c1.798-1.998 2.518-3.995 2.807-5.993' }],
    ['path', { d: 'M15 2c-1.798 1.998-2.518 3.995-2.807 5.993' }],
    ['path', { d: 'm17 6-2.5-2.5' }],
    ['path', { d: 'm14 8-1-1' }],
    ['path', { d: 'm7 18 2.5 2.5' }],
    ['path', { d: 'm3.5 14.5.5.5' }],
    ['path', { d: 'm20 9 .5.5' }],
    ['path', { d: 'm6.5 12.5 1 1' }],
    ['path', { d: 'm16.5 10.5 1 1' }],
    ['path', { d: 'm10 16 1.5 1.5' }],
  ],
];

export default Dna;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Hammer: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm15 12-8.5 8.5c-.83.83-2.17.83-3 0 0 0 0 0 0 0a2.12 2.12 0 0 1 0-3L12 9',
      },
    ],
    ['path', { d: 'M17.64 15 22 10.64' }],
    [
      'path',
      {
        d: 'm20.91 11.7-1.25-1.25c-.6-.6-.93-1.4-.93-2.25v-.86L16.01 4.6a5.56 5.56 0 0 0-3.94-1.64H9l.92.82A6.18 6.18 0 0 1 12 8.4v1.56l2 2h2.47l2.26 1.91',
      },
    ],
  ],
];

export default Hammer;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronFirst: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '17 18 11 12 17 6' }],
    ['path', { d: 'M7 6v12' }],
  ],
];

export default ChevronFirst;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const UserCog: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2' }],
    ['circle', { cx: '9', cy: '7', r: '4' }],
    ['circle', { cx: '19', cy: '11', r: '2' }],
    ['path', { d: 'M19 8v1' }],
    ['path', { d: 'M19 13v1' }],
    ['path', { d: 'm21.6 9.5-.87.5' }],
    ['path', { d: 'm17.27 12-.87.5' }],
    ['path', { d: 'm21.6 12.5-.87-.5' }],
    ['path', { d: 'm17.27 10-.87-.5' }],
  ],
];

export default UserCog;

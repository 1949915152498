import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Play: IconNode = [
  'svg',
  defaultAttributes,
  [['polygon', { points: '5 3 19 12 5 21 5 3' }]],
];

export default Play;

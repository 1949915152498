import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronLeft: IconNode = [
  'svg',
  defaultAttributes,
  [['polyline', { points: '15 18 9 12 15 6' }]],
];

export default ChevronLeft;

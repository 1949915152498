import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Building2: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M6 22V4c0-.27 0-.55.07-.82a1.477 1.477 0 0 1 1.1-1.11C7.46 2 8.73 2 9 2h7c.27 0 .55 0 .82.07a1.477 1.477 0 0 1 1.11 1.1c.07.28.07.56.07.83v18H6Z',
      },
    ],
    [
      'path',
      {
        d: 'M2 14v6c0 1.1.9 2 2 2h2V12H4c-.27 0-.55 0-.82.07-.27.07-.52.2-.72.4-.19.19-.32.44-.39.71A3.4 3.4 0 0 0 2 14Z',
      },
    ],
    [
      'path',
      {
        d: 'M20.82 9.07A3.4 3.4 0 0 0 20 9h-2v13h2a2 2 0 0 0 2-2v-9c0-.28 0-.55-.07-.82-.07-.27-.2-.52-.4-.72-.19-.19-.44-.32-.71-.39Z',
      },
    ],
    ['path', { d: 'M10 6h4' }],
    ['path', { d: 'M10 10h4' }],
    ['path', { d: 'M10 14h4' }],
    ['path', { d: 'M10 18h4' }],
  ],
];

export default Building2;

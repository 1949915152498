import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Check: IconNode = [
  'svg',
  defaultAttributes,
  [['polyline', { points: '20 6 9 17 4 12' }]],
];

export default Check;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Milk: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M8 2h8' }],
    [
      'path',
      {
        d: 'M9 2v2.789a4 4 0 0 1-.672 2.219l-.656.984A4 4 0 0 0 7 10.212V20a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-9.789a4 4 0 0 0-.672-2.219l-.656-.984A4 4 0 0 1 15 4.788V2',
      },
    ],
    ['path', { d: 'M7 15a6.472 6.472 0 0 1 5 0 6.47 6.47 0 0 0 5 0' }],
  ],
];

export default Milk;

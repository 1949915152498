import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const SunDim: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z' }],
    ['path', { d: 'M12 4h.01' }],
    ['path', { d: 'M20 12h.01' }],
    ['path', { d: 'M12 20h.01' }],
    ['path', { d: 'M4 12h.01' }],
    ['path', { d: 'M17.657 6.343h.01' }],
    ['path', { d: 'M17.657 17.657h.01' }],
    ['path', { d: 'M6.343 17.657h.01' }],
    ['path', { d: 'M6.343 6.343h.01' }],
  ],
];

export default SunDim;

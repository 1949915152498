import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Sandwich: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 11v3a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-3' }],
    [
      'path',
      {
        d: 'M12 19H4a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-3.83',
      },
    ],
    ['path', { d: 'm3 11 7.77-6.04a2 2 0 0 1 2.46 0L21 11H3Z' }],
    ['path', { d: 'M12.97 19.77 7 15h12.5l-3.75 4.5a2 2 0 0 1-2.78.27Z' }],
  ],
];

export default Sandwich;

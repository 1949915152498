import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Thermometer: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M14 4v10.54a4 4 0 1 1-4 0V4a2 2 0 0 1 4 0Z' }]],
];

export default Thermometer;

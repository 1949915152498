import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ServerCog: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M5 10H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-1',
      },
    ],
    [
      'path',
      {
        d: 'M5 14H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-1',
      },
    ],
    ['path', { d: 'M6 6h.01' }],
    ['path', { d: 'M6 18h.01' }],
    ['circle', { cx: '12', cy: '12', r: '3' }],
    ['path', { d: 'M12 8v1' }],
    ['path', { d: 'M12 15v1' }],
    ['path', { d: 'M16 12h-1' }],
    ['path', { d: 'M9 12H8' }],
    ['path', { d: 'm15 9-.88.88' }],
    ['path', { d: 'M9.88 14.12 9 15' }],
    ['path', { d: 'm15 15-.88-.88' }],
    ['path', { d: 'M9.88 9.88 9 9' }],
  ],
];

export default ServerCog;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Circle: IconNode = [
  'svg',
  defaultAttributes,
  [['circle', { cx: '12', cy: '12', r: '10' }]],
];

export default Circle;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Swords: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '14.5 17.5 3 6 3 3 6 3 17.5 14.5' }],
    ['line', { x1: '13', y1: '19', x2: '19', y2: '13' }],
    ['line', { x1: '16', y1: '16', x2: '20', y2: '20' }],
    ['line', { x1: '19', y1: '21', x2: '21', y2: '19' }],
    ['polyline', { points: '14.5 6.5 18 3 21 3 21 6 17.5 9.5' }],
    ['line', { x1: '5', y1: '14', x2: '9', y2: '18' }],
    ['line', { x1: '7', y1: '17', x2: '4', y2: '20' }],
    ['line', { x1: '3', y1: '19', x2: '5', y2: '21' }],
  ],
];

export default Swords;

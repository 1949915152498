import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Ruler: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M21.3 8.7 8.7 21.3c-1 1-2.5 1-3.4 0l-2.6-2.6c-1-1-1-2.5 0-3.4L15.3 2.7c1-1 2.5-1 3.4 0l2.6 2.6c1 1 1 2.5 0 3.4Z',
      },
    ],
    ['path', { d: 'm7.5 10.5 2 2' }],
    ['path', { d: 'm10.5 7.5 2 2' }],
    ['path', { d: 'm13.5 4.5 2 2' }],
    ['path', { d: 'm4.5 13.5 2 2' }],
  ],
];

export default Ruler;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const WheatOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm2 22 10-10' }],
    ['path', { d: 'm16 8-1.17 1.17' }],
    [
      'path',
      {
        d: 'M3.47 12.53 5 11l1.53 1.53a3.5 3.5 0 0 1 0 4.94L5 19l-1.53-1.53a3.5 3.5 0 0 1 0-4.94Z',
      },
    ],
    [
      'path',
      {
        d: 'm8 8-.53.53a3.5 3.5 0 0 0 0 4.94L9 15l1.53-1.53c.55-.55.88-1.25.98-1.97',
      },
    ],
    [
      'path',
      {
        d: 'M10.91 5.26c.15-.26.34-.51.56-.73L13 3l1.53 1.53a3.5 3.5 0 0 1 .28 4.62',
      },
    ],
    ['path', { d: 'M20 2h2v2a4 4 0 0 1-4 4h-2V6a4 4 0 0 1 4-4Z' }],
    [
      'path',
      {
        d: 'M11.47 17.47 13 19l-1.53 1.53a3.5 3.5 0 0 1-4.94 0L5 19l1.53-1.53a3.5 3.5 0 0 1 4.94 0Z',
      },
    ],
    [
      'path',
      {
        d: 'm16 16-.53.53a3.5 3.5 0 0 1-4.94 0L9 15l1.53-1.53a3.49 3.49 0 0 1 1.97-.98',
      },
    ],
    [
      'path',
      {
        d: 'M18.74 13.09c.26-.15.51-.34.73-.56L21 11l-1.53-1.53a3.5 3.5 0 0 0-4.62-.28',
      },
    ],
    ['line', { x1: '2', y1: '2', x2: '22', y2: '22' }],
  ],
];

export default WheatOff;

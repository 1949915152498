import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Activity: IconNode = [
  'svg',
  defaultAttributes,
  [['polyline', { points: '22 12 18 12 15 21 9 3 6 12 2 12' }]],
];

export default Activity;

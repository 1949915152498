import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Star: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'polygon',
      {
        points:
          '12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2',
      },
    ],
  ],
];

export default Star;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Zap: IconNode = [
  'svg',
  defaultAttributes,
  [['polygon', { points: '13 2 3 14 12 14 11 22 21 10 12 10 13 2' }]],
];

export default Zap;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const FileBox: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M14.5 22H18a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4' }],
    ['polyline', { points: '14 2 14 8 20 8' }],
    [
      'path',
      {
        d: 'M2.97 13.12c-.6.36-.97 1.02-.97 1.74v3.28c0 .72.37 1.38.97 1.74l3 1.83c.63.39 1.43.39 2.06 0l3-1.83c.6-.36.97-1.02.97-1.74v-3.28c0-.72-.37-1.38-.97-1.74l-3-1.83a1.97 1.97 0 0 0-2.06 0l-3 1.83Z',
      },
    ],
    ['path', { d: 'm7 17-4.74-2.85' }],
    ['path', { d: 'm7 17 4.74-2.85' }],
    ['path', { d: 'M7 17v5' }],
  ],
];

export default FileBox;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Dice4: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '3', y: '3', width: '18', height: '18', rx: '2', ry: '2' }],
    ['path', { d: 'M16 8h.01' }],
    ['path', { d: 'M8 8h.01' }],
    ['path', { d: 'M8 16h.01' }],
    ['path', { d: 'M16 16h.01' }],
  ],
];

export default Dice4;

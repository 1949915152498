import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowDownCircle: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '12', r: '10' }],
    ['polyline', { points: '8 12 12 16 16 12' }],
    ['line', { x1: '12', y1: '8', x2: '12', y2: '16' }],
  ],
];

export default ArrowDownCircle;

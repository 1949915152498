import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const SunMoon: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z' }],
    ['path', { d: 'M12 8a2.828 2.828 0 1 0 4 4' }],
    ['path', { d: 'M12 2v2' }],
    ['path', { d: 'M12 20v2' }],
    ['path', { d: 'm4.93 4.93 1.41 1.41' }],
    ['path', { d: 'm17.66 17.66 1.41 1.41' }],
    ['path', { d: 'M2 12h2' }],
    ['path', { d: 'M20 12h2' }],
    ['path', { d: 'm6.34 17.66-1.41 1.41' }],
    ['path', { d: 'm19.07 4.93-1.41 1.41' }],
  ],
];

export default SunMoon;

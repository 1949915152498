import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const FlaskConical: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M10 2v7.527a2 2 0 0 1-.211.896L4.72 20.55a1 1 0 0 0 .9 1.45h12.76a1 1 0 0 0 .9-1.45l-5.069-10.127A2 2 0 0 1 14 9.527V2',
      },
    ],
    ['path', { d: 'M8.5 2h7' }],
    ['path', { d: 'M7 16h10' }],
  ],
];

export default FlaskConical;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Diamond: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'rect',
      {
        x: '12',
        y: '1',
        width: '15.56',
        height: '15.56',
        rx: '2.41',
        transform: 'rotate(45 12 1)',
      },
    ],
  ],
];

export default Diamond;

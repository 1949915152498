import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const StarHalf: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M12 17.8 5.8 21 7 14.1 2 9.3l7-1L12 2' }]],
];

export default StarHalf;

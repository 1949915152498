import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const AlignJustify: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '3', y1: '6', x2: '21', y2: '6' }],
    ['line', { x1: '3', y1: '12', x2: '21', y2: '12' }],
    ['line', { x1: '3', y1: '18', x2: '21', y2: '18' }],
  ],
];

export default AlignJustify;

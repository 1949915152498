import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronsLeft: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '11 17 6 12 11 7' }],
    ['polyline', { points: '18 17 13 12 18 7' }],
  ],
];

export default ChevronsLeft;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Scale3d: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M5 7v12h12' }],
    ['path', { d: 'm5 19 6-6' }],
    ['rect', { x: '3', y: '3', width: '4', height: '4', rx: '1' }],
    ['rect', { x: '17', y: '17', width: '4', height: '4', rx: '1' }],
  ],
];

export default Scale3d;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ThermometerSnowflake: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 12h10' }],
    ['path', { d: 'M9 4v16' }],
    ['path', { d: 'm3 9 3 3-3 3' }],
    ['path', { d: 'M12 6 9 9 6 6' }],
    ['path', { d: 'm6 18 3-3 1.5 1.5' }],
    ['path', { d: 'M20 4v10.54a4 4 0 1 1-4 0V4a2 2 0 0 1 4 0Z' }],
  ],
];

export default ThermometerSnowflake;

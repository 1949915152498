import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const FilePieChart: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M16 22h2a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v3' }],
    ['polyline', { points: '14 2 14 8 20 8' }],
    ['path', { d: 'M4.04 11.71a5.84 5.84 0 1 0 8.2 8.29' }],
    ['path', { d: 'M13.83 16A5.83 5.83 0 0 0 8 10.17V16h5.83Z' }],
  ],
];

export default FilePieChart;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronLast: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '7 18 13 12 7 6' }],
    ['path', { d: 'M17 6v12' }],
  ],
];

export default ChevronLast;

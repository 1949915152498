import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Slice: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm8 14-6 6h9v-3' }],
    ['path', { d: 'M18.37 3.63 8 14l3 3L21.37 6.63a2.12 2.12 0 1 0-3-3Z' }],
  ],
];

export default Slice;

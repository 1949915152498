import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Unlink2: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M15 7h2a5 5 0 0 1 0 10h-2m-6 0H7A5 5 0 0 1 7 7h2' }]],
];

export default Unlink2;

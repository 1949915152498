import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const AlignHorizontalJustifyStart: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '6', y: '5', width: '6', height: '14', rx: '2' }],
    ['rect', { x: '16', y: '7', width: '6', height: '10', rx: '2' }],
    ['path', { d: 'M2 2v20' }],
  ],
];

export default AlignHorizontalJustifyStart;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Wallet: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 12V8H6a2 2 0 0 1-2-2c0-1.1.9-2 2-2h12v4' }],
    ['path', { d: 'M4 6v12c0 1.1.9 2 2 2h14v-4' }],
    ['path', { d: 'M18 12a2 2 0 0 0-2 2c0 1.1.9 2 2 2h4v-4h-4z' }],
  ],
];

export default Wallet;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Triangle: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z',
      },
    ],
  ],
];

export default Triangle;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Bomb: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '11', cy: '13', r: '9' }],
    [
      'path',
      {
        d: 'm19.5 9.5 1.8-1.8a2.4 2.4 0 0 0 0-3.4l-1.6-1.6a2.41 2.41 0 0 0-3.4 0l-1.8 1.8',
      },
    ],
    ['path', { d: 'm22 2-1.5 1.5' }],
  ],
];

export default Bomb;

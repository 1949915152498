import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Anchor: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '5', r: '3' }],
    ['line', { x1: '12', y1: '22', x2: '12', y2: '8' }],
    ['path', { d: 'M5 12H2a10 10 0 0 0 20 0h-3' }],
  ],
];

export default Anchor;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Stethoscope: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M4.8 2.3A.3.3 0 1 0 5 2H4a2 2 0 0 0-2 2v5a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6V4a2 2 0 0 0-2-2h-1a.2.2 0 1 0 .3.3',
      },
    ],
    ['path', { d: 'M8 15v1a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6v-4' }],
    ['circle', { cx: '20', cy: '10', r: '2' }],
  ],
];

export default Stethoscope;
